<template>
  <div
    class="imp-faq"
    :class="{'imp-faq_mobile':isMobile}"
  >
    <div
      class="imp-faq__header"
      :class="{'imp-faq__header_mobile':isMobile}"
    >
      FAQ’s
    </div>
    <template v-for="item in itemsShort">
      <FaqItem
        :key="item.id"
        :item="item"
        :isMobile="isMobile"
      />
    </template>
    <template
      v-for="item in items"
      v-if="isShowItems"
    >
      <FaqItem
        :key="item.id"
        :item="item"
        :isMobile="isMobile"
      />
    </template>
    <div
      class="imp-faq-button"
      :class="{'imp-faq-button_mobile':isMobile}"
    >
      <a
        class="imp-faq-button__item btn button-new-design prolend-primary  mt-2 text-center"
        href="#"
        @click.prevent="onButtonClick"
      >
        {{ isShowItems ? 'View less' : 'View more' }}
      </a>
    </div>
  </div>
</template>

<script>
const FaqItem = () => import('@/components/faq/faqItem');

export default {
  name: 'Faq',
  components: {
    FaqItem,
  },
  props: ['isMobile'],
  data: () => ({
    itemsShort: [
      {
        id: 1,
        title: 'What’s included in your Limited Time Offer $25/month website package? ',
        description: 'We are all about helping small nonprofits grow. For $25/month, any small organization will get our premium website, CRM, Donations platform, Forms, and Graphics Assistant! We can also create a custom design for you or work with your designer. You can also add now or later our value-added solutions such as custom mobile apps, TV apps, and podcasts. ',
      },
      {
        id: 2,
        title: 'We’d like to buy your All-in-One solution, what is the price for that Premium package?',
        description: 'With the current limited-time offer, you can get our Premium package for a $250 one-time setup fee and $75/month. This package inlcludes everything you need to grow your organization: next-gen website, custom mobile app, TV apps, podcasts, donations platform, CRM and more! \n'
          + 'Make sure to apply the “VIP” coupon during checkout. ',
      },
      {
        id: 3,
        title: 'Do I have to buy the whole package, or can I get just a mobile app?',
        description: 'We provide our platform as all-in-one or unbundled! You can purchase any package separately, whether it be just the website, mobile app, or TV apps and podcasts. Our giving platform, CRM, Forms, and media assistant are included in our limited-time offer.',
      },
    ],
    items: [
      {
        id: 4,
        title: 'If I sign up during your limited time offer campaign, will the pricing change later? ',
        description: 'No, the pricing will not change for you if you will be one of the first 500 to sign up. ',
      },
      {
        id: 5,
        title: 'I already have an app and I want to switch to your apps. Can you help?',
        description: 'Many organizations are switching to us, and we can undoubtedly help transfer your app from another provider to us or your certificate if necessary. Please have all this information ready before contacting us.',
      },
      {
        id: 6,
        title: 'What are the fees for the giving platform? ',
        description: 'No Setup. No Monthly Fees. Only 2.3% transaction fee in the USA and 1.4% in Nigeria. All money will be deposited directly to your bank account from our partner processing company. We will help you set up an account with them and not pay any PCI compliance or monthly fees. ',
      },
      {
        id: 7,
        title: 'Are there discounts for paying 1 or 2 years upfront? ',
        description: 'Yes, if you pay one year upfront, in addition to our limited time offer you’ll get a 10% discount or 20% for two years. ',
      },
      {
        id: 8,
        title: 'Do you have customer support?',
        description: 'Yes, we’d be happy to provide support when you need it! You can use online chat widget inside our admin system or email us at support@impactfactors.net ',
      },
      {
        id: 9,
        title: 'Do you require a contract? ',
        description: 'We do not require a contract for the website only. If you purchase a Mobile or TV apps package, we require a 2-years contract because of the amount of work it takes to create and publish your apps. ',
      },
      {
        id: 10,
        title: 'Can I talk to someone at your company? ',
        description: `
        Yes, please use this link to pick a time to talk to one of our team members:
        <a class="imp-faq-item__link" target="_blank" href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01">https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01</a>
        Alternatively, you can send a message here. Or feel free to email us at sales@impactfactors.net`,
      },
      {
        id: 11,
        title: 'How to login into my admin portal?',
        description: 'Please go to our website <a target="_blank" href="https://www.impactfactors.net/">https://www.impactfactors.net/</a> and on the upper right corner click “Client Login”.',
      },
      {
        id: 12,
        title: 'Why are websites crucial for churches?',
        description: `
            <p>Everything is shifting to online. Churches are not an exception. People consume tons of content every day, sometimes falling asleep in front of their favorite books opened in some app, or watching their favorite TV series. </p>
            <p>To build a community, church leaders have to think how to nail content marketing to make their word visible and hearable to all generations. According to numerous researches, even the 45+ audience is now turning to online content, leave alone youth. </p>
            <p>A well-thought-through website with compelling design and brilliant, fast navigation is a way to engage more and more visitors. Your content is probably the strongest value, but a website is as important as the case that actually carries all your content and makes it visible to readers.</p>
            <p>Content marketing and strong web presence allow you to acquire and engage visitors, converting them to registered users and eventually to one-time and recurring donors, who are willing to be a part of your community and stay with you for many years on.</p>`,
      },
      {
        id: 13,
        title: 'What’s the most important in creating websites for churches?',
        description: `
            <p>As with any website, a church website owner should first consider what are the goals they want to achieve with the help of a website. Once it’s done, you can outline a plan for your website and understand how you should design and promote your website. </p>
            <p>Building a portrait of your readers is also recommended if you want to align your services and content with your audience’s needs and interests. Doing market research is an important step at this stage.</p>
            <p>When you proceed to a website building, it’s crucial to choose a team that will understand your goals and unite their efforts towards achieving those goals. A website is your boat: you step onto it and go sailing to the web, doing marketing activities, like SEO or paid promotion. But the boat is something that prompts you forward while you learn how to master the waves: it should be built according to all technical guidelines, best practices, and with your goals in mind.</p>
            <p>Note also that there are numerous technical requirements from Google that you should pay attention to if you want your site to occupy high rankings in organic search and not drown in it. These guidelines include the domain name, CMS, loading speed, images, pages’ markups, etc. If you want to build a successful website, you should consider taking a strategic approach to that.</p>`,
      },
      {
        id: 14,
        title: 'Are there free websites for churches? ',
        description: 'Yes, but as they say, free cheese is only in a mousetrap. If you’re serious about growing your community, turning to professionals is a must in this case. We\'ve built  an extremely easy, all-in-one tool that has been successfully helping church leaders to distribute their content online, being highly digestible and understanding to readers.',
      },
    ],
    isShowItems: false,
  }),
  methods: {
    onButtonClick() {
      if (this.isShowItems) {
        const sectionArea = document.getElementsByClassName('imp-faq');
        window.scroller.scrollTo(sectionArea[0]);
        setTimeout(() => {
          this.isShowItems = false;
        }, 520);
      } else {
        this.isShowItems = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';

  .imp-faq {
    max-width: 758px;
    width: 100%;
    margin: 0 auto;
    padding: 127px 30px 113px;
    color: $color-white;
    min-height: 0;
    transition: .5s;

    &_mobile {
      padding: 58px 27px 72px;
    }

    &__header {
      text-align: center;
      font-size: 64px;
      line-height: 68px;
      font-weight: 700;
      margin-bottom: 60px;

      &_mobile {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 35px;
      }
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 54px;

      &__item {
        color: $color-white !important;

        &:after {
          background-color: transparent !important;
          border: 1px solid $color-white !important;
        }
      }

      &_mobile {
        margin-top: 16px;
      }
    }
  }

</style>
